<template>
  <div class="clinics-container">
    <div class="d-flex justify-content-between">
      <span class="title">
        Clinicas
      </span>

      <div>
        <b-dropdown
          text="Alterar status"
          variant="primary"
          size="md"
          class="ml-auto mr-2"
          :disabled="!selectedClinics?.length"
        >
          <b-dropdown-item @click="onUpdateClinicType('MAIN')">
            Padrão
          </b-dropdown-item>
          <b-dropdown-item @click="onUpdateClinicType('BV')">
            BV
          </b-dropdown-item>
          <b-dropdown-item @click="onUpdateClinicType('SANDBOX')">
            Sandbox
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <div>
      <b-table-simple class="table" striped>
        <thead>
          <tr>
            <td></td>
            <td class="td-header">Clínica</td>
            <td class="td-header">Tipo</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="clinic in clinics" :key="clinic.id">
            <td>
              <Check              
                :value="selectedClinics.indexOf(clinic) === -1 ? false : true"
                @change="selectClinic(clinic)"
              />
            </td>
            <td>{{ clinic.name }}</td>
            <td>{{ parseType(clinic.type) }}</td>
          </tr>
        </tbody>
      </b-table-simple>
    </div>   
  </div>
</template>
<script>

export default {
  props: {
    clinics: Array,
    getAllClinics: Function
  },
  components: {
    Check: () => import('@/components/General/Check')
  },
  data() {
    return {
      selectedClinics: [],
    }
  },
  methods: {
    parseType(type){
      switch(type) {
        case 'MAIN': return 'Padrão'
        case 'SANDBOX': return 'Sandbox'
        case 'BV': return 'BV'
      }
    },

    selectClinic(clinic){
      if(this.selectedClinics.indexOf(clinic) === -1) this.selectedClinics.push(clinic)
      else this.selectedClinics.splice(this.selectedClinics.indexOf(clinic), 1)
    },

    onUpdateClinicType(type) {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">Deseja realmente editar o tipo das clínicas selecionadas?</div>`,
        showCancelButton: true,
        confirmButtonColor: '#305BF2 ',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
       }).then(async res => {
        if (res.isConfirmed) {
          const loading = this.$loading.show()
          try {
            const ids = this.selectedClinics.map(clinic => clinic.id)
            ids.forEach(async id => {
              await this.updateClinic(id, {type: type})
            })
            this.$toast.success('Tipo das clínicas editado com sucesso')
            setTimeout(() => {
              this.getAllClinics() 
            }, 300)
            this.selectedClinics = []
            loading.hide()
          } catch (err) {
            loading.hide()
            this.$toast.error(err.message)
          }
        }
      })
    },

    updateClinic(id, data) {
      this.api.updateClinicData(id, data)
        .catch(err => {
          this.$toast.error(err.message)
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.clinics-container {
  padding: 24px;

  .table {
    .td-header {
      font-weight: 700;
      color: var(--active-type);
    }
    td {
      border-top: none;
      vertical-align: middle;
    }
    .full-width {
      width: 100%;
    }
  }
}
</style>
